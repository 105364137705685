import Apis from '@/api/apis'
export default {
  comboBaseCode(value) {
    let listData = []; 
    Apis.baseCodeList({
      division: value, 
      },(res) => {  
        if (res.result) {
          for (let i in res.data) {
          listData.push({
            code: res.data[i].basecode,
            name: res.data[i].description,
            color: res.data[i].color,
            icon: res.data[i].icon,
          });
          }
        } else {
          alert(res.message);
        }
      }).catch( (err) => {  // API 오류 처리
          console.log("API 호출 오류")
          alert(err);
      }
    )
    return listData;  
  },
// 사업자번호 체크
  checkBizNo(number) {
    let numberMap = number.replace(/-/gi, '').split('').map(function (d) {
      return parseInt(d, 10);
    }); 
    
    if (numberMap.length === 10) {
        let keyList = [1, 3, 7, 1, 3, 7, 1, 3, 5]; 
        let chk = 0; 
        keyList.forEach(function (d, i) {
          chk += d * numberMap[i]; }); 
          chk += parseInt((keyList[8] * numberMap[8]) / 10, 10);
          return Math.floor(numberMap[9]) === ((10 - (chk % 10)) % 10); 
      } 
    return false; 
  },
  // 법인등록번호 체크
  checkRegNo(number) {
    // let re = /-/g;
    number = number.replace('-','');

    if (number.length != 13){
      return false;
    }

    let arr_regno  = number.split("");
    let arr_wt   = new Array(1,2,1,2,1,2,1,2,1,2,1,2);
    let iSum_regno  = 0;
    let iCheck_digit = 0;

    let i = 0;
    for (i = 0; i < 12; i++){
      iSum_regno +=  eval(arr_regno[i]) * eval(arr_wt[i]);
    }

    iCheck_digit = 10 - (iSum_regno % 10);

    iCheck_digit = iCheck_digit % 10;

    if (iCheck_digit != arr_regno[12]){
      return false;
    }
    return true;
  },
  // 폰번호 입력마스킹
  getMask( phoneNumber ) {
    if(!phoneNumber) return phoneNumber
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '')

    let res = ''
    if(phoneNumber.length < 3) {
        res = phoneNumber
    }
    else {
        if(phoneNumber.substr(0, 2) =='02') {
            if(phoneNumber.length <= 5) {//02-123-5678
                res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
            } else if(phoneNumber.length > 5 && phoneNumber.length <= 9) {//02-123-5678
                res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5)
            } else if(phoneNumber.length > 9) {//02-1234-5678
                res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6)
            }
        } else {
            if(phoneNumber.length < 8) {
                res = phoneNumber
            } else if(phoneNumber.length == 8) {
                res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
            } else if(phoneNumber.length == 9) {
                res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
            } else if(phoneNumber.length == 10) {
                res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
            } else if(phoneNumber.length > 10) { //010-1234-5678
                res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7)
            }
        }
    }
    return res
  },
  // 등록번호 입력마스킹
  getRegMask( regNumber ) {
    if(!regNumber) return regNumber
    regNumber = regNumber.replace(/[^0-9]/g, '')

    let res = ''
    if(regNumber.length < 10) {
        res = regNumber
    } else if(regNumber.length < 11) {
        res = regNumber.substr(0, 3) + '-' + regNumber.substr(3,2) + '-' + regNumber.substr(5)
    } else {
        res = regNumber.substr(0, 6) + '-' + regNumber.substr(6)
    }
    return res
  },
  // Mac Address
  getMacMask( mac ) {
    if(!mac) return mac;
    mac = mac.toLowerCase();
    mac = mac.replace(/[^0-9a-fA-F:]+$/,'');
    mac = mac.replace(/:/gi, '');
    
    if (mac.length < 3) {
      return mac
    } else if (mac.length < 5) {
      return mac.substr(0,2) + ':' +  mac.substr(2)
    } else if (mac.length < 7) {
        return mac.substr(0,2) + ':' + mac.substr(2,2)+ ':' + mac.substr(4) 
    } else if (mac.replace(/[^0-9]/g) && mac.length == 8) {
      return mac.substr(0,4) + ':' + mac.substr(4,5)
    // } else if (mac.replace(/[^0-9]/g) && mac.length == 11) {
    //   return mac.substr(0,4) + ':' + mac.substr(5,4) + ':' + mac.substr(9,2)
    } else if (mac.length < 9) {
        return mac.substr(0,2) + ':' + mac.substr(2,2)+ ':' + mac.substr(4,2)+ ':' + mac.substr(6)
    } else if (mac.length < 11) {
        return mac.substr(0,2) + ':' + mac.substr(2,2)+ ':' + mac.substr(4,2)+ ':' + mac.substr(6,2)+ ':' + mac.substr(8)
    } else if (mac.length < 13) {
        return mac.substr(0,2) + ':' + mac.substr(2,2)+ ':' + mac.substr(4,2)+ ':' + mac.substr(6,2)+ ':' + mac.substr(8,2)+ ':' + mac.substr(10)
    } else {    
        return mac.substr(0,2) + ':' + mac.substr(2,2)+ ':' + mac.substr(4,2)+ ':' + mac.substr(6,2)+ ':' + mac.substr(8,2)+ ':' + mac.substr(10,2)+ ':' + mac.substr(12,2)
    }
        // return value.replace(/^(\S{2})(\S{2})(\S{2})(\S{2})(\S{2})$/, `$1:$2:$3:$4:$5`);
  },


  /* BH Etri_Ui 에서 copy */
  /* 구글 플레이스토어 링크 */
  connectPlayStore() {
    window.open("https://play.google.com/store/apps/details?id=com.homebbar");
  },
  convertTimestamp(timestamp) {
    var d = new Date(timestamp),        // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ('0' + (d.getMonth() + 1)).slice(-2),        // Months are zero based. Add leading 0.
      dd = ('0' + d.getDate()).slice(-2),                       // Add leading 0.
      hh = d.getHours(),
      h = hh,
      min = ('0' + d.getMinutes()).slice(-2),           // Add leading 0.
      ampm = 'AM',
      time;

    if (hh > 12) {
      h = hh - 12;
      ampm = 'PM';
    } else if (hh === 12) {
      h = 12;
      ampm = 'PM';
    } else if (hh == 0) {
      h = 12;
    }

    // ie: 2013-02-18, 8:35 AM
    time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
    time = h + ':' + min + ' ' + ampm;

    return time;
  },

  // 소수점 2자리만 남기기
  cutDot2(value) {
    let intV = Math.floor(value * 100)
    return intV / 100.0
  },

  getTextLength(str) {
    var len = 0;
    for (var i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length == 6) {
            len++;
        }

        len++;
    }

    return len;
  },

  cutStr(str, start, size) {
    var i = 0;
    var lim = 0;
    var pos = 0;
    var beg = 0;
    var len = this.getTextLength(str);

    for (i = 0; pos < start; i++) {
        pos += (str.charCodeAt(i) > 128) ? 2 : 1;
    }

    beg = i;


    for (i = beg; i < len; i++) {
        lim += (str.charCodeAt(i) > 128) ? 2 : 1;

        if (lim > size) {
            break;
        }
    }

    return str.substring(beg, i);
  },

  isNumeric(num, opt){
    // 좌우 trim(공백제거)을 해준다.
    var regex;
    num = String(num).replace(/^\s+|\s+$/g, "");
    if(typeof opt == "undefined" || opt == "1"){
      // regex = /^[+\-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
      regex = /^[+-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
    }else if(opt == "2"){
      // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
      regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
    }else if(opt == "3"){
      // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
      regex = /^[0-9]+(\.[0-9]+)?$/g;
    }else{
      // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
      regex = /^[0-9]$/g;
    }
    if( regex.test(num) ){
      num = num.replace(/,/g, "");
      return isNaN(num) ? false : true;
    }else{
      return false;
    }
  },

  // 도각법 풍향에서 16방위 명 추출
  getDirection(deg) {
    // 16방위각
    // let directions = ['↓ 북풍', '↙ 북북동풍', '↙ 북동풍', '↙ 동북동풍', '← 동풍', '↖ 동남동풍', '↖ 남동풍', 
    // '↖ 남남동풍','↑ 남풍', '↗ 남남서풍','↗ 남서풍', '↗ 서남서풍','→ 서풍', '↘ 서북서풍', '↘ 북서풍','↘ 북북서풍'];
    // return directions [parseInt( ((Number(deg) + 11.25)  / (360 / 16))) % 16 ];
    
    // 8방위각
    deg = Math.abs(deg)
    let directions = ['↓ 북풍', '↙ 북동풍', '← 동풍', '↖ 남동풍', '↑ 남풍', '↗ 남서풍', '→ 서풍', '↘ 북서풍',];
    return directions [parseInt( ((Number(deg) + 22.5)  / (360 / 8))) % 8 ];
  },
  getWindValue(deg) {
    // 16방위각
    // let directions = ['↓ 북풍', '↙ 북북동풍', '↙ 북동풍', '↙ 동북동풍', '← 동풍', '↖ 동남동풍', '↖ 남동풍', 
    // '↖ 남남동풍','↑ 남풍', '↗ 남남서풍','↗ 남서풍', '↗ 서남서풍','→ 서풍', '↘ 서북서풍', '↘ 북서풍','↘ 북북서풍'];
    // return directions [parseInt( ((Number(deg) + 11.25)  / (360 / 16))) % 16 ];
    
    // 8방위각
    deg = Math.abs(deg)
    let directions = [337.5, 22.5, 67.5, 112.5, 157.5, 202.5, 247.5, 292.5,];
    return directions [parseInt( ((Number(deg) + 22.5)  / (360 / 8))) % 8 ];
  },
  dataUnit(dataname){
      switch (dataname)
      {
        case "co2","nh3","h2s" : return "ppm ";
        case "temp" : return "°C ";
        case "hum" : return "% ";
        case "bp" : return "hPa ";
        case "ws" : return "m/s ";
        case "wd" : return "° ";
        default:  return "ppm ";
      }  
    },
  
}
 