<template>
  <v-container>
    <div class="weather" v-for="(weather,i) in deodorizerDevice_weather" :key="i">
      <div class="info_date">{{weather.search_date}}</div>

      <div class="weather_info" >
        <div style="text-align:center" class="mr-1">
          <img
            :src="icon_sunny"
            v-show="weather.weather === 'clear' && weather.search_date >=  weather.sunrise && weather.search_date <=  weather.sunset"
          />
          <img
            :src="icon_sunny_night"
            v-show="weather.weather === 'clear' && (weather.search_date >  weather.sunset || weather.search_date <  weather.sunrise)"
          />
          <!-- 흐림 -->
          <img
            :src="icon_cloudy"
            v-show="weather.weather === 'cloudy'"
          />
          <!-- 약간 흐림 || 안개 -->
          <img
            :src="icon_partly_cloudy"
            v-show="(weather.weather === 'few clouds' || weather.weather === 'mist') && 
                    weather.search_date.substr(11,2) < '19' && weather.search_date.substr(11,2) > '06'"
          />
          <img
            :src="icon_partly_cloudy_night"
            v-show="(weather.weather === 'few clouds' || weather.weather === 'mist') && 
                    weather.search_date.substr(11,2) >= '19' && weather.search_date.substr(11,2) <= '06'"
          />
          <!-- 이슬비 -->
          <img :src="icon_drizzle" v-show="weather.weather === 'drizzle'" />
          <!-- 비 -->
          <img :src="icon_rain" v-show="weather.weather === 'rain'" />
          <!-- 뇌우 -->
          <img
            :src="icon_thunder"
            v-show="weather.weather === 'thunderstorm'"
          />
          <!-- 눈 -->
          <img :src="icon_snow" v-show="weather.weather === 'snow'" />

          <div class="temp_info">
            <v-icon color="#6B7B6E" small>mdi-thermometer</v-icon>
            <span style="font-size:13px;">
              {{weather.temp}}
              <span class="unit">℃</span>
            </span>
          </div>
        </div>

        <div>
          <div class="wind_info ml-2 mr-2">
            <span class="weather_title mr-1"> 기상 </span>

            <div  class="value_info">
              <v-icon color="#6B7B6E">mdi-weather-windy</v-icon>
          
              <span class="ml-2">
                <span class="wind_speed">{{weather.wind.speed}}</span>
                <span class="unit">m/s &nbsp; | </span>
                <span class="ml-1"> {{ getdirection(i) }} </span>
              </span>
            </div>

            <div class="ml-3">
              <v-icon color="#617F8F">mdi-speedometer</v-icon>
              <span>
                {{weather.pressure}}
                <span class="unit">hPa &nbsp;</span>
              </span>
            </div>
          </div>

          <div class="wind_info ml-2 mr-2" v-if="weather.sensor && weather.sensor.pressure">
            <span class="weather_title mr-1"> 센서 </span>

            <div  class="value_info">
              <v-icon color="#6B7B6E">mdi-weather-windy</v-icon>

              <span class="ml-2">
                <span span class="wind_speed">{{weather.sensor.wind.speed}}</span>
                <span class="unit">m/s &nbsp; | </span>
                <span class="ml-1"> {{ getSensordirection(i) }} </span>
              </span>
            </div>

            <div class="ml-3">
              <v-icon color="#617F8F">mdi-speedometer</v-icon>
              <span>
                {{weather.sensor.pressure}}
                <span class="unit">hPa &nbsp;</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Common from "@/utils/custom/common.js";

export default {
  name: "DeodorizerDeviceWeather",
  
  props: {
    deodorizerDevice_weather: {
      type:Array,
    },
  },

  data() {
    return {
      icon_sunny: require("@/assets/monitoring/sunny.svg"),
      icon_sunny_night: require("@/assets/monitoring/sunny_night.svg"),
      icon_cloudy: require("@/assets/monitoring/cloudy.svg"),
      icon_partly_cloudy: require("@/assets/monitoring/partly_cloudy.svg"),
      icon_partly_cloudy_night: require("@/assets/monitoring/partly_cloudy_night.svg"),
      icon_rain: require("@/assets/monitoring/rain.svg"),
      icon_thunder: require("@/assets/monitoring/thunder.svg"),
      icon_snow: require("@/assets/monitoring/snow.svg"),
      icon_windy: require("@/assets/monitoring/windy.svg"),
      icon_drizzle: require("@/assets/monitoring/drizzle.svg"),
      icon_strong_wind: require("@/assets/monitoring/strong_wind.svg"),
      icon_refresh: require("@/assets/monitoring/icon_refresh.svg"),

      
      // pressure:this.deodorizer_weather.pressure,
      // search_date:this.deodorizer_weather.search_date,
      // icon_refresh: require("@/assets/monitoring/icon_refresh.svg"),
    };
  },
  
  methods: {
    refresh() {
      this.$emit("refreshList");
    },
    getdirection(i){
      return Common.getDirection(this.deodorizerDevice_weather[i].wind.deg);
    },
    getSensordirection(i){
      return Common.getDirection(this.deodorizerDevice_weather[i].sensor.wind.deg);
    }

  },
};
</script>
<style lang="scss" scoped>
.weather{
  background-color:white;
  border: 1.5px solid #6B7B6E;
  border-radius: 20px;
}
.weather_info{
  display:flex;
  margin:10px;
  margin-top:0px;
  justify-content: center;
  align-items: center;
  letter-spacing: -1px;
  color: #3a4f3f;
  flex-wrap:wrap;
}

.weather_title{
  background-color:#9EB0A2;
  letter-spacing: -2px;
  color:white;
  padding:2px;
  width:50px;
  text-align:center;
  font-size:13px;
  border-radius:15px;
}
  .value_info{
    width:180px;
  }
  .wind_speed{
    display:inline-block; 
    width:40px;
  }

.wind_info, .wind_info_sensor{
  display:flex;
  align-items: center;
  letter-spacing: -1px;
  color:#3a4f3f;
}

.wind_value{
  font-size:13px;
  text-align: center;
  letter-spacing: -1px;
}
img{
  width: 40px;
  height: 40px;
}
.temp_info{
  margin-top:-15px;
}
.unit{
  font-size:11px;
}

.info_date{
  text-align:center;
  font-size:14px;
  letter-spacing: -1px;
  color:#6B7B6E;
}

@media screen and (max-width:1680px){
  img{
  width: 35px;
  height: 35px;
  }
  .value_info{
    width:165px;
  }
  .wind_speed{
    display:inline-block; 
    width:40px;
  }
}

@media screen and (max-width: 768px){
  .weather{
    display:none;
  }
}
</style>
