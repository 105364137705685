<template>
  <v-container fluid >
    <v-card outlined max-width="60%" class="mx-auto pa-1 mb-1 rounded-xl"  >
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 150px;"
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 150px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>

        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refresh()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card> 

    <v-tabs color="#3a4f3f" centered v-model="tab">
      <v-tab v-for="plc in items" :key="plc.plc_name" class="tab">
        <pre><span v-if="plc.watt" class="watt pl-3 pr-3">{{ plc.watt }}<span style="font-size:12px;"> kW</span><span style="font-size:11px;"> / 1일</span></span>
{{ plc.plc_name }}
</pre>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-1">
      <v-tab-item  v-for="plc_id in items" :key="plc_id.mac">
        <v-card max-width="80%" class="mx-auto treeview" outlined color="transparent" >
          <div class="tree__monitor">
            <div class="tree_warning_monitor" v-if="plc_id.warning != null"> 
              <div class="warning_plc blink"> 
                <img class="warning_plc_icon" :src="warning_plc" />
                <div class="warning_plc_info">{{ plc_id.warning }}</div>
              </div>
            </div>

            <div class="weather__info" >

              <div v-if="$getters.userInfo.grade < 'UT06'" class="entry__setting">
                <div class="auto_set mr-1">
                  <div class="entry_title mb-">전체</div>
                  <img class="entrymode_icon allrun" :src="automode" @click=Allautocontrol(plc_id) />
                  <img class="entrymode_icon allmanual" :src="manualmode" @click=Allpoweron(plc_id)  />
                  <img class="entrymode_icon allstop" :src="allstop" @click=Allpoweroff(plc_id) />
                </div>
              </div>

              <div>
                <DeodorizerDeviceWeather
                  :deodorizerDevice_weather="deodorizerDevice_weather"/>
              </div>
            </div>

            <div class="tree_container">
            <div id="tree" >
              <div class="entry_first " v-for="(control, c) in plc_id.children" :key="c">
                <div class="control__info compressor_error blink" 
                  v-if="control.type.code ==='OD01' && ( control.status.err_comp_max_pressure == 'on' || control.status.err_comp_signal == 'on' ) ">
                    <img class="warning_icon" :src="warning_img" />
                  <div>
                    <div class="warning__info pb-1" v-if="control.status.err_comp_max_pressure == 'on'"> 컴프레서 압력 이상</div>
                    <div class="warning__info" v-if="control.status.err_comp_signal == 'on'"> 컴프레서 신호 이상</div>
                  </div>
                </div>

                <div class="control__info">
                  <!-- 콤프레서 -->
                  <div class="deodorizer__icon" v-if="control.type.code === 'OD01'">
                    <img class="compressor_icon" :src="compressor" />
                  </div>

                  <!-- 탱크 -->
                  <div v-if="control.type.code === 'OD03'">
                    <div class="deodorizer__icon" v-for="level in plc_id.children[c].status" :key="level">
                      <div v-if="level === 'level_bottom'">  
                        <img class="tank_icon" :src="level_bottom" /><span class="level_info">최소 이하</span>
                      </div>  
                      <div v-else-if="level === 'level_low'">  
                        <img class="tank_icon" :src="level_low"/><span class="level_info">낮음</span>
                      </div>  
                      <div v-else-if="level === 'level_middle'">  
                        <img class="tank_icon" :src="level_middle"/><span class="level_info">중간</span>
                      </div>  
                      <div v-else-if="level === 'level_top'">  
                        <img class="tank_icon" :src="level_top"/><span class="level_info">높음</span>
                      </div>  
                      <div v-else>  
                        <img class="error_icon" :src="level_error"/>
                      </div>
                    </div>
                  </div>

                  <div> {{ control.device_name }}</div>
                </div>

                <div class="branch" v-if="control.children.length > 0">
                  <div class="device__entry" v-for="(device, d) in control.children" :key="d">
                    <div class="device_pump_info compressor_error blink" v-if="device.type.code ==='OD04' && device.status.err_pump == 'on' ">
                      <img class="warning_icon" :src="warning_img" style="height:55px;" />
                      <div>
                        <div class="warning_device_info" v-if="device.status.err_pump == 'on'"> 펌프 이상 </div>
                      </div>
                    </div>

                    <div class="device_pump_info"  v-if="device.type.code === 'OD04'"> 
                      <div class="deodorizer__icon">
                        <img class="pump_icon" :src="pump" >

                        <div class="pump__info"> 
                          <div class="ml-1 mb-2" style="font-size:17px; font-weight:900">
                            <v-icon class="mr-1" color="#3a4f3f">mdi-radio-tower</v-icon> 동작 정보</div>
                          <div class="pump__detail mb-2">
                            <div>용량 (최소-최대) : 
                              <span> {{ device.minoutput }} L/분 </span>  
                              <span> - </span> 
                              <span> {{ device.maxnoutput }} L/분 </span>
                            </div>

                            <div class="mt-2" style="background-color:white; border-radius:10px; padding:5px;">
                              <v-icon color="#3a4f3f"> mdi-refresh mdi-spin </v-icon> 현재 가동 용량 : 
                              <span class="ml-1" style="font-size:17px;">{{ device.status.runningvolume }}</span> 
                              <span> L/분</span>
                            </div>
                          </div>

                          <div class="pump__detail">
                            <div>
                              가동시간 : 
                              <span>{{ device.minruntime }} 분 </span>
                              <span> - </span>
                              <span>{{ device.maxruntime }} 분 </span>
                            </div>
                            <div> 최소 중단 시간 : {{ device.minstoptime }} 분</div>
                            <div class="mt-2" style="background-color:white; border-radius:10px; padding:5px; ">
                              <v-icon color="#3a4f3f">mdi-clock mdi-spin</v-icon>현재 연속 가동 시간 : 
                              <span class="ml-1 mr-1" style="font-size:0.9rem; width:80px;"> {{ device.status.now == "run" ? device.status.runningtime : 0 }}</span>
                              <span>분</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="device__name">{{  device.device_name }}</div>
                    </div>

                    <div class="device_hocl_info"  v-if="device.type.code === 'OD02'"> 
                      <div class="deodorizer__icon">
                        <img class="hocl_icon" :src="hocl" />
                      </div>
                      <div class="hocl__name">{{  device.device_name }}</div>
                    </div>

                    <div class="deodorizer_branch" v-if="device.children.length > 0">
                      <div class="entry" v-for="(deodorizer, z) in device.children" :key="z">
                        <div class="deodorizer__error compressor_error blink" style="display:flex" 
                          v-if="(deodorizer.type ==='OT04' || deodorizer.type === 'OT03') && ( deodorizer.err_scrubber_fan == 'on' || deodorizer.err_scrubber_pump == 'on' ) ">
                          <img class="deodorizer_warning_icon mr-1" :src="warning_img" />
                          <div style="display:flex;">
                            <div class="deodorizer_warning__info" v-if="deodorizer.err_scrubber_fan == 'on'"> 스크라버 팬 이상</div>
                            <div class="deodorizer_warning__info ml-1 mr-1" v-if="deodorizer.err_scrubber_fan == 'on' && deodorizer.err_scrubber_pump  == 'on'"> /</div>
                            <div class="deodorizer_warning__info" v-if="deodorizer.err_scrubber_pump  == 'on'"> 스크라버 펌프 이상</div>
                        </div>
                      </div>
                      
                      <div class="deodorizer__name" :class="{device_run: deodorizer.running === 'on', gray: device.status.err_pump == 'on'}">
                        <div class="deodorizer__info"> 
                          <img class="wash_icon" :src="wash" v-if=" deodorizer.cleaning === 'on'"/>
                          <div style="display:flex; font-size:17px; letter-spacing: -1px;">
                            <img class="line_icon run" :src="deodorizer_line" v-if="deodorizer.type === 'OT01' && deodorizer.running === 'on'"/>
                            <img class="line_icon stop" :src="deodorizer_line" v-if="deodorizer.type === 'OT01' && deodorizer.running === 'off'"/>
                            <img class="tower_icon run" :src="deodorizer_tower" v-if="(deodorizer.type === 'OT03' || deodorizer.type === 'OT04') && deodorizer.running === 'on' "/>
                            <img class="tower_icon stop" :src="deodorizer_tower" v-if="(deodorizer.type === 'OT03' || deodorizer.type === 'OT04')  && deodorizer.running === 'off' "/>
                            
                            {{ deodorizer.device_name }}({{ deodorizer.map_no }}) 
                          </div>

                          <!-- 모드표시 영역 -->
                          <div style="display:flex; align-items: center;">
                            <div class="mode_icon auto" v-if="deodorizer.mode === 'auto'"> 
                              <v-icon color="white" size="12">mdi-autorenew mdi-spin</v-icon>
                              제어
                            </div>
                            <img :src="switch_on" class="power_icon" @click=poweroff(deodorizer) v-else-if="deodorizer.mode === 'manual' && deodorizer.running === 'on'"/> 
                            <img :src="switch_off" class="power_icon" @click=poweron(deodorizer) v-else-if="deodorizer.mode === 'manual' && deodorizer.running === 'off'"/>
                            <div class="mode_icon local" v-else-if="deodorizer.mode === 'local'"> 현장 </div>
                            <div v-else class="warning_icon_small mr-2">
                              <img :src="warning_img" class="warning_icon_small mr-2">
                              <div class="warning__notice"> 
                                관리자에게 문의하세요. <br/>
                                [ 원스프링 CS ] 041-406-7775
                              </div>
                            </div>
                          
                            <!-- 자동.수동 전환 표시영역 -->
                            <div class="auto__control ml-1" v-if="deodorizer.mode !== 'local'">
                              <div class="mr-1" style="font-size:14px;">자동</div>
                              <img :src="play" class="auto_icon pt-1" @click=autocontrol(deodorizer) 
                                :class="{run_mode: deodorizer.mode === 'manual', disabledbutton: !deodorizer.auto_enable}" v-if="deodorizer.mode === 'manual'" />
                              <img :src="stop" class="auto_icon pt-1" @click="manualcontrol(deodorizer)" 
                                :class="{stop_mode:deodorizer.mode === 'auto',disabledbutton: !deodorizer.auto_enable}" v-else-if="deodorizer.mode === 'auto' "/>
                              <div class="manual pt-1"  v-else-if="deodorizer.mode === 'local'" style="display:none;"> </div>
                              <div class="manual pt-1" :class="{disabledbutton: deodorizer.auto_enable}" v-else @click="manualcontrol(deodorizer)"> </div>
                              <img :src="setting" class="auto_icon pt-1" @click="controlset(deodorizer.id)" />
                              </div>
                              <div class="local_auto_control ml-1" v-if="deodorizer.mode === 'local'" @click="controlset(deodorizer.id)"> 자동설정</div>
                            </div>       
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </v-card>

        <v-card max-width="80%" class="mx-auto treeview" outlined color="transparent" >
          <div class="mt-3">
            <div v-if="plc_id.histories.length > 0" style="display:flex;">
              <div class="history__title pt-2" @click="historydialog()">
                <v-icon color=white size="25">mdi-history</v-icon>
                이력 정보
              </div>

              <v-dialog
              v-model="dialog"
              max-width="500">

                <v-card class="pa-4" style="border-radius:10px;">
                  <div class="history__title">
                    <v-icon color=white size="25">mdi-history</v-icon>
                    상세 이력
                  </div>

                    <div class="histories__contents" v-for="(histories,h) in plc_id.histories" :key="h"> 
                      <div style="padding:5px; letter-spacing: -1px; line-height:20px;">
                        <div style="font-weight:500;" :class="{result_blue: histories.result === true, result_red: histories.result == false }">
                          {{ histories.msg }}
                        </div>
                        <div style="font-size:15px;">
                          <span class="mr-1"> {{ histories.setdate }} </span> 
                          <span class="mr-1" style="font-weight: 900; "> | </span>
                          <span> {{ histories.setuser }} </span>
                          
                        </div>
                      </div>
                    </div>
                    
                <v-card-actions class="justify-center mt-2">
                  <v-btn class="priority_btn" rounded outlined @click="dialog=false">
                    확인
                  </v-btn>
                </v-card-actions>
                </v-card>
              </v-dialog>

              <div class="histories__contents ml-2 pl-5 pr-5">
                <span> {{ plc_id.histories[0].setdate }} </span>
                <span class="mr-1" style="font-weight:900"> | </span>
                <span class="mr-1" :class="{result_blue: plc_id.histories[0].result === true, result_red: plc_id.histories[0].result == false }" > {{ plc_id.histories[0].msg }}  </span>
                
              </div>
            </div>
          </div>
        </v-card>

      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
import DeodorizerDeviceWeather from "./child/DeodorizerDeviceWeather.vue";

var setTime = 0;

export default{
  name: "DeodorizerDviceMonitoring",

  components:{
    DeodorizerDeviceWeather,
  },

  data(){
    return{
      level_bottom: require("@/assets/deodorizer/water_tank_bottom.svg"),
      level_low: require("@/assets/deodorizer/water_tank_low.svg"),
      level_middle: require("@/assets/deodorizer/water_tank_middle.svg"),
      level_top: require("@/assets/deodorizer/water_tank_top.svg"),
      level_error : require("@/assets/deodorizer/water_level_error.svg"),

      compressor : require("@/assets/deodorizer/air_compressor.svg"),
      pump : require("@/assets/deodorizer/pump.svg"),
      hocl : require("@/assets/deodorizer/hocl.svg"),


      deodorizer_tower: require("@/assets/deodorizer/deodorizer_tower.svg"),
      deodorizer_line: require("@/assets/deodorizer/deodorizer_line.svg"),

      wash : require("@/assets/deodorizer/deodorizer_wash.svg"),
      warning_img : require("@/assets/deodorizer/warning.svg"),
      warning_plc : require("@/assets/deodorizer/wraning_plc.svg"),
      
      watt: require("@/assets/deodorizer/watt.svg"),
      error: require("@/assets/deodorizer/error.svg"),

      switch_on: require("@/assets/deodorizer/switch_on.svg"),
      switch_off: require("@/assets/deodorizer/switch_off.svg"),
  
      power: require("@/assets/monitoring/power.svg"),
      
      play: require("@/assets/deodorizer/play.svg"),
      stop: require("@/assets/deodorizer/stop.svg"),
      setting: require("@/assets/deodorizer/setting.svg"),

      automode: require("@/assets/deodorizer/AutoMode.svg"),
      manualmode: require("@/assets/deodorizer/ManualMode.svg"),
      allstop: require("@/assets/deodorizer/AllStop.svg"),


      deodorizerDevice_weather: [],

      company : {},
      companyItems : [],
      farm: {},
      farmItems: [],

      dialog:false,
      
      items:[],
      tab:null,

      loading: false,
    }
  },
  async created(){
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.comboCompanies();
    await this.comboFarms();

    let cookie_info = VueCookies.get("DeodorizerDviceMonitoring"); // 이전 조회정보를 참조
    if (!this.isNull(this.$route.params)) {
      // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      this.tab = this.$route.params.tab;
    } else if (cookie_info) {
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
        this.tab = cookie_info.tab || 0;
    } else {  // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
        this.farm = this.farmItems[0];
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        this.tab = 0;
    }

    this.refreshList();
  },
  destroyed() {
    clearTimeout(setTime);
    // console.log("clearTimeout",setTime);
  },

  methods:{
    refresh() {
      clearTimeout(setTime);
      this.refreshList();
    },
    refreshWeatherInfo() {
      Apis.weatherForDeodorizer({
        farm_cd:  this.farm && this.farm.code,
      } ,(res) => {
        // NOTE: 해당 농장/사업장정보에 등록된 위치정보 기반 날씨 정보
        // console.log("weather=",res.data)

        this.deodorizerDevice_weather = res.data;

        // console.log("deg", res.data[0].wind)
      });
    },
    refreshList(){
      // this.$store.commit("resMessage","");
      this.refreshWeatherInfo();


      if (!this.farm || !this.farm.code ) {
        alert("농장/사업장을 선택하세요.");
        this.$refs.farm.focus();
        return;
      }
      this.loading = true;

      let cookie_info = {company: this.company, farm: this.farm, tab: this.tab};
      VueCookies.set("DeodorizerDviceMonitoring" ,cookie_info,"30d");

      Apis.treeDeodorizerDviceMonitoring({
        farm_cd:  this.farm && this.farm.code,
      } ,(res) => {  // 정상처리
        if (res.resultCode != "N000" ) {
          this.$store.commit("resMessage",res.message);
          setTimeout( () =>  this.$store.commit("resMessage",""), 7 * 1000);
          }
        else if (this.$route.path == "/deodorizer/DeodorizerDviceMonitoring") {setTime = setTimeout( () =>  this.refreshList(), 7 * 1000);}
        this.items = res.data;
        this.loading = false;
        // console.log("setTime",setTime);

      }).catch( (err) => {  // 개별 API 오류 처리 예제
        this.loading = false;
        console.log("treeDeodorizerDviceMonitoring 호출 오류",err);
        if (this.$route.path == "/deodorizer/DeodorizerDviceMonitoring") setTime = setTimeout( () =>  this.refreshList(), 7 * 1000);
        // alert("treeDeodorizerDviceMonitoring 호출 오류" + err);
      });
    },

    historydialog(){
      event.stopPropagation();    // 상위박스 클릭이벤트 중복방지
      this.$store.commit("resMessage","");
      // console.log(item);
      this.dialog = true;
    },

    async Allpoweron(plc){
      event.stopPropagation();
      // console.log(plc);
      if (!confirm("해당 기계실 전체 탈취라인을 수동 작동하시겠습니까?")) return;
      clearTimeout(setTime);
      this.loading = true;
      // let result = false;

      await Apis.deodorizerOnAllLines({
        plc: { mac: plc.mac, slave_id: plc.slave_id, device_name: plc.plc_name, id: plc.plc_id},
        under_control: false,   // 최소가동 한도 적용 여부?
        over_control: false,   // 최대가동 한도 적용 여부?

      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // result = res.result;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("deodorizerOnAllLines 호출 오류",err);
        alert("deodorizerOnAllLines 호출 오류" + err);
      });
      // if (result)  this.refresh();
      this.refresh();
      setTimeout( () =>  this.$store.commit("resMessage",""), 15 * 1000);
    },

    async Allpoweroff(plc){
      event.stopPropagation();
      if (!confirm("해당 기계실 전체 탈취라인을 수동 중지하시겠습니까?"))  return;

      clearTimeout(setTime);
      this.loading = true;
      // let result = false;

      await Apis.deodorizerOffAllLines({
        plc: { mac: plc.mac, slave_id: plc.slave_id, device_name: plc.plc_name, id: plc.plc_id},
        under_control: false,   // 최소가동 한도 적용 여부?
        over_control: false,   // 최대가동 한도 적용 여부?

      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // result = res.result;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("deodorizerOffAllLines 호출 오류",err);
        alert("deodorizerOffAllLines 호출 오류" + err);
      });
      // if (result)  this.refresh();
      this.refresh();
      setTimeout( () =>  this.$store.commit("resMessage",""), 15 * 1000);
    },

    async Allautocontrol(plc){
      event.stopPropagation();
      if (!confirm("해당 기계실 전체 탈취라인을 자동제어 시작 하시겠습니까?"))  return;

      clearTimeout(setTime);
      this.loading = true;
      // let result = false;

      await Apis.deodorizerAutoAllLines({
        plc: { mac: plc.mac, slave_id: plc.slave_id, device_name: plc.plc_name, id: plc.plc_id},
        under_control: false,   // 최소가동 한도 적용 여부?
        over_control: false,   // 최대가동 한도 적용 여부?

      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // result = res.result;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("deodorizerAutoAllLines 호출 오류",err);
        alert("deodorizerOAutoAllLines 호출 오류" + err);
      });
      // if (result)  this.refresh();
      this.refresh();
      setTimeout( () =>  this.$store.commit("resMessage",""), 15 * 1000);
    },

    async poweron(deodorizer){
      event.stopPropagation();
      if (this.$getters.userInfo.grade > 'UT06') {alert("제어 권한이 없는 사용자 입니다");  return;}
      if (!confirm("해당 디바이스를 작동하시겠습니까?"))  return;
      clearTimeout(setTime);

      this.loading = true;
      // let result = false;
      await Apis.deodorizerOnLines({
        deodorizer: deodorizer,
        under_control: false,   // 최소가동 한도 적용 여부?
        over_control: false,   // 최대가동 한도 적용 여부?

      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // result = res.result;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("deodorizerOnLines 호출 오류",err);
        alert("deodorizerOnLines 호출 오류" + err);
      });
      // if (result)  this.refresh();
      this.refreshList();
      setTimeout( () =>  this.$store.commit("resMessage",""), 15 * 1000);
      // this.loading = false;
    },

    async poweroff(deodorizer){
      event.stopPropagation();
      if (this.$getters.userInfo.grade > 'UT06') {alert("제어 권한이 없는 사용자 입니다");  return;}
      if (!confirm("해당 디바이스를 정지하시겠습니까?"))  return;

      clearTimeout(setTime);
      this.loading = true;
      // let result = false;
      await Apis.deodorizerOffLines({
        deodorizer: deodorizer,
        under_control: false,   // 최소가동 한도 적용 여부?
        over_control: false,   // 최대가동 한도 적용 여부?

      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // result = res.result;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("deodorizerOffLines 호출 오류",err);
        alert("deodorizerOffLines 호출 오류" + err);
      });
      // if (result)  this.refresh();
      this.refresh();
      setTimeout( () =>  this.$store.commit("resMessage",""), 15 * 1000);
    },

    async autocontrol(deodorizer){
      event.stopPropagation();
      if (this.$getters.userInfo.grade > 'UT06') {alert("제어 권한이 없는 사용자 입니다");  return;}
      if (!confirm("자동 제어를 시작하시겠습니까?"))  return;
      clearTimeout(setTime);
      this.loading = true;
      // let result = false;
      await Apis.deodorizerAutoLines({
        deodorizer: deodorizer,
        under_control: false,   // 최소가동 한도 적용 여부?
        over_control: false,   // 최대가동 한도 적용 여부?

      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // result = res.result;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("deodorizerAutoLines 호출 오류",err);
        alert("deodorizerAutoLines 호출 오류" + err);
      });
      // if (result)  this.refresh();
      this.refresh();
      setTimeout( () =>  this.$store.commit("resMessage",""), 15 * 1000);
    },

    async manualcontrol(deodorizer){
      event.stopPropagation();

      if (this.$getters.userInfo.grade > 'UT06') {alert("제어 권한이 없는 사용자 입니다");  return;}
      if (!confirm("수동 제어로 전환하시겠습니까?"))  return;
      clearTimeout(setTime);
      this.loading = true;
      // let result = false;
      await Apis.deodorizerManualLines({
        deodorizer: deodorizer,
        under_control: false,   // 최소가동 한도 적용 여부?
        over_control: false,   // 최대가동 한도 적용 여부?

      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // result = res.result;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("deodorizerManualLines 호출 오류",err);
        alert("deodorizerManualLines 호출 오류" + err);
      });
      // if (result)  this.refresh();
      this.refresh();
      setTimeout( () =>  this.$store.commit("resMessage",""), 15 * 1000);
    },

    controlset(id){
      let cookie_info = {company: this.company, farm: this.farm, tab: this.tab};
      VueCookies.set("DeodorizerDviceMonitoring" ,cookie_info,"30d");
      
      this.$router.push({
        name: "DeodorizerSet",
        params: {
          id: id
        }
      });
    },

    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },

    async changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },        

    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        this.refresh();
      }
    },

  } // methods end
}
</script>


<style lang="scss" scoped>
*{
  /* 드래그 방지 */
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}

.tab{
  font-size:15px;
  font-weight:bold;
  letter-spacing: -1.25px;
  width:250px;
}

.tree__monitor{
  border:1px solid #e4e4e4;
  height:750px;
  // padding: 10px;
  overflow: auto;
}
.tree_warning_monitor{
  background-color: rgba( 220,220,220, 0.6 );
  position:absolute;
  width:100%;
  height:750px;
  overflow: auto;
  z-index:7;
}
.warning_plc{
  display:flex;
  height:80px;
  line-height:80px;
  margin:100px;
  justify-content: center;
}
.warning_plc_info{
  font-size:45px;
  letter-spacing:-2px;
  font-weight:bold;
  line-height:80px;
  color:#88191a;
}

.warning_plc_icon{
  width:150px;
  // height:150px;
}
.weather__info{
  display:flex;
  position:absolute;
  top:1px; 
  right:0;
  z-index:5;
  margin:5px;
}
.entry__setting{
  display:flex;
  margin-right:-8px;
  margin-top:15px;
  z-index:5;
}
.entry_title{
  font-size:15px;
  letter-spacing: -1px;
  font-weight:bold;
}
.auto_set{
  background-color:#f5f5f5;
  height:80px;
  padding:5px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-align:center;
}
.entrymode_icon{
  width:45px;
  cursor:pointer;
}
.allrun{
  filter: invert(25%) sepia(16%) saturate(735%) hue-rotate(82deg) brightness(94%) contrast(83%);
}
.allrun:hover{
  filter:invert(79%) sepia(4%) saturate(936%) hue-rotate(81deg) brightness(88%) contrast(85%);
}
.allmanual{
  filter: invert(25%) sepia(64%) saturate(684%) hue-rotate(164deg) brightness(93%) contrast(96%);
}
.allmanual:hover{
  filter: invert(49%) sepia(61%) saturate(168%) hue-rotate(155deg) brightness(86%) contrast(93%);
}
.allstop{
  filter: invert(12%) sepia(63%) saturate(4173%) hue-rotate(346deg) brightness(87%) contrast(93%);
}
.allstop:hover{
  filter: invert(91%) sepia(3%) saturate(1778%) hue-rotate(318deg) brightness(72%) contrast(116%);
}
.notice__info{
  position:absolute;
  top:1px; 
  right:0;
  // margin-right:10px;
  width:200px;
  // padding:5px;
  z-index:5;
}
.notice__title{
  padding:5px;
  border:2px solid #3a4f3f;
  border-radius: 15px;
  height: 35px;
  line-height:35px;
  font-size:17px;
  font-weight: bold;
  letter-spacing: -1px;
  color:#3a4f3f;
  text-align: center;
}
.notice__title:hover{
  background-color:#BCD5C1;
  transition: all 300ms ease-in-out;
}
.weather_info{
  width:375px;
}
.error__info{
  background-color:white;
  padding:10px;
  letter-spacing: -1px;
  border-radius: 10px;
}
.history__info{
  width:200px;
  // border: 2px solid #3a4f3f;
}
.history__title{
  text-align: center;
  background-color:#3a4f3f;
  color:white;
  border-radius:10px;
  width: 130px;
  padding:5px;
  font-weight:17px; 
  font-weight:bold; 
  letter-spacing:-1px;
  cursor:pointer;
}
.histories__contents{
  margin-top:7px;
  padding: 5px;
  letter-spacing: -1px;
  border-radius: 10px;
  background-color: #F5F5F5;
}
.level_info{
  font-size:15px;
  margin-left:-5px;
}
.priority_btn{
  width:130px;
  height:45px !important;
  font-size:17px;
  letter-spacing: -1px;
  font-weight:bold;
  background-color:#3a4f3f;
  color:white;

}

#tree {
  display: inline-block;
}

#tree * {
  box-sizing: border-box;
}

#tree .branch {
  padding: 40px 0 5px 60px; 
}
#tree .branch:not(:first-child) {
  margin-left: 250px; // 브랜치 위치 움직임 (인버터 펌프)
}

#tree .branch:not(:first-child):after {
  content: "";
  width: 70px;
  border-top: 2px solid #ccc;
  position: absolute;
  left: 250px; // 컨트롤러 다음 (-) 브랜치 움직임
  top: 50%;
  margin-top: 23px;
}
#tree .deodorizer_branch {
  margin-left: 350px; // 나뭇가지 움직임 (탈취라인)
}

#tree .deodorizer_branch:after {
  content: "";
  width:80px;
  border-top: 2px solid #ccc;
  position: absolute;
  left: 280px; // 인버터펌프 다음 탈취라인 전 (-) 브랜치 움직임
  top: 50%;
  margin-top: 10px;
}
.entry_first {
  min-height: 150px !important; //뿌리 줄기 높이 해당 지정하면 높이 올라감
}

.entry_first:last-child:before {
  width: 15px !important;
}
.entry, .device__entry, .entry_first {
  position: relative;
  min-height: 65px; //뿌리 줄기 높이 해당 지정하면 높이 올라감
  display: block;
}

.entry:before , .device__entry:before, .entry_first:before {
  content: "";
  height: 100%;
  border-left: 2px solid #ccc;
  position: absolute;
  left: 10px;
}

.entry:first-child:after, .device__entry:first-child:after, .entry_first:first-child:after {
  height: 80px;
  border-radius: 10px 0 0 0;
}

.entry:first-child:before, .device__entry:first-child:before, .entry_first:first-child:before {
  width: 20px;
  height: 50%;
  top: 50%;
  margin-top: 1px;
  border-radius: 10px 0 0 0;
}

.entry:after, .device__entry:after, .entry_first:after  {
  content: "";
  width: 20px;
  transition: border 0.5s;
  border-top: 2px solid #ccc;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: 1px;
}

.entry:last-child:before, .device__entry:last-child:before, .entry_first:last-child:before{
  width: 20px;
  height: 50%;
  border-radius: 0 0 0 10px;
}
.entry:last-child:after, .device__entry:last-child:after, .entry_first:last-child:after {
  height: 10px;
  border-top: none;
  transition: border 0.5s;
  border-bottom: 2px solid #ccc;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.entry:only-child:after {
  width: 20px;
  height: 11px; // 펌프에서 연결 디바이스가 한개밖에 없을 때
  margin-top:1px;
  border-radius: 0px;
}
.device__entry:only-child:after {
  width: 21px;
  height: 27.5px; // 탱크에서 연결 디바이스가 한개밖에 없을 때
  margin-top: 0px;
  border-radius: 0px;
}
.entry:only-child:before, .device__entry:only-child:before {
  display: none;
}
// 박스 관련
.entry span, .device_pump_info, .device_hocl_info, .control__info, .deodorizer__error, .deodorizer__name{
  border: 1px solid #ccc;
  display: block;
  box-shadow: 0 -1.5px 2px #d6d6d6 inset;
  background: #fdfdfd;
  min-width: 250px;
  
  height:40px;
  line-height: 30px;

  font-size: 18px;
  letter-spacing: -1px;

  padding: 5px 10px;

  text-align: center;
  margin-left: 30px; // 박스 움직임
  position: absolute;
  left: 50;
  top: 50%;
  margin-top: -15px;
  color: #666;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.5s;
}
.control__info{
  min-width: 220px !important;
  
  height:130px !important;
  line-height:150px !important;

  font-size:17px !important;
  margin-left:30px !important;
  margin-top: -50px !important;
}
.device_pump_info{
  height:90px !important;
  line-height:40px !important;

  font-size:17px !important;
  margin-top: -20px !important;
}
.pump_icon:hover + .pump__info {
  display: block;
  transition: all 200ms ease-in-out;
}
.pump__info{
  z-index:1 !important;
  display: none;
  position: absolute;
  width: 260px;
  padding: 10px;
  top:90px;
  right:-5px;
  letter-spacing: 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 2px solid #3a4f3f;
  background-color: white;
  color: #3a4f3f;
  text-align:left;
  font-size: 15px;
  line-height:25px;
  letter-spacing: -1px;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 

}

.pump__info:after{
  z-index:1 !important;
  position: absolute;
  bottom: 100%;
  // left: 50%;
  width: 0;
  height: 0;
  margin-left: 40px;
  border: solid transparent;
  border-color: rgba(51, 51, 51, 0);
  border-bottom-color: #333;
  border-width: 10px;
  pointer-events: none;
  content: ' ';
}
.pump__detail{
  background-color:#F5F5F5;
  font-weight:600;
  // line-height:25px;
  padding:10px;
  border-radius: 10px;

}
.device_hocl_info{
  height:60px;
  font-size:16px !important;
  margin-top: -20px !important;
}
.device__name{
  margin-top:14px;
  letter-spacing: -1.5px;
}
.hocl__name{
  margin-top:-3px;
  font-size:14px;
  letter-spacing: -1.5px;
}

.deodorizer__name{
  min-width:500px !important;
  // min-width:100% !important;
  height:45px !important;
  line-height:45px !important;
  overflow:visible;
}

@media (min-width:1920px){
  .tree_container{
    top:50%;
    left:30%;
    transform: translate(-50%, -50%);
    position:absolute;
  }
}

@media (max-width:1920px) {
  .treeview{
    max-width:95% !important;
  }
  #tree{
    padding: 15px 10px 5px 50px;
  }
}

@media  (max-width: 850px) {
  .treeview{
    max-width:100% !important;
  }
  .notice__info{
    display:none;
  }
  .deodorizer__name {
    min-width:400px !important;
  }
  .wash_icon,.line_icon, .tower_icon {
    display:none;
  }
}

.deodorizer__error{
  min-width:500px !important;
  height:45px !important;
  justify-content: center;
}
.deodorizer__info{
  display:flex;
  justify-content: space-between;
  align-items: center; 
  line-height:30px;
  font-size: 17px;
  letter-spacing: -1.5px;
}
.compressor_error{
  z-index:1;
  // background-color:black;
  background-color: rgba( 220,220,220, 0.5 );
}
.warning__info{
  margin-top:-135px;
  font-weight:bold;
  letter-spacing: -1px;
  color:#88191a;
}
.deodorizer_warning__info{
  font-weight:bold;
  letter-spacing: -1px;
  color:#88191a;
}

.warning_device_info{
  margin-top:-20px;
  font-weight:bold;
  letter-spacing: -1px;
  color:#88191a;
}
.auto__control, .local_auto_control{
  // cursor: pointer;
  justify-content: center; 
  display:flex;
  width:100px;
  height:28px;
  line-height:28px;
  border-radius: 15px;
  font-size:12px; 
  font-weight:bold; 
  letter-spacing:-1px;
  background-color:#6A806F;
  color:white;
}
.local_auto_control{
  cursor: pointer;
  width:60px;
  font-size:13px;
}
.watt{
  color:white;
  background-color:#6A806F;
  border-radius: 10px;
  font-size:14px;
}
.watt__info{
  display:flex;
  height:30px;
  line-height:30px;
}
.watt__content{
  background-color:white;
  width:120px;
  text-align: center;
  font-size:18px;
  letter-spacing: -1px;
  border-radius: 15px;
}

// 아이콘
.watt_icon{
  width:25px;
  height:25px;
}
.deodorizer__icon{
  font-size:12px;
  height:30px;
  letter-spacing: -1px;
  margin-top:1px;
}
.compressor_icon{
width:80px;
height:70px;
}
.tank_icon{
  width:90px;
  height:80px;
}
.error_icon{
  width:60px;
  height:70px;
}
.pump_icon{
  width:70px;
  height:50px;
}
.hocl_icon{
  width:70px;
  height:35px;
}
.wash_icon{
  width:20px;
}
.line_icon{
  width:20px;
  height:20px;
  margin-top:5px;
  margin-right:3px;
}
.deodorizer_warning_icon{
  width:30px;
}
.run{
  filter:invert(79%) sepia(4%) saturate(936%) hue-rotate(81deg) brightness(88%) contrast(85%);
}
.stop{
  filter: invert(94%) sepia(0%) saturate(11%) hue-rotate(165deg) brightness(90%) contrast(86%);
}
.tower_icon{
  width:20px;
  height:20px;
  margin-top:5px;
}
.mode_icon{
  border-radius: 20px;
  font-size:14px;
  letter-spacing: -1px;
  height:24px;
  line-height:24px;
  width:42px;
  font-weight:bold;
}
.auto{
  width:45px;
  background-color:#005643; 
  color:white;
  font-size:13px;
}
.manual{
  width:25px;
  cursor: pointer;
  background-color:#3a4f3f;
  color:white;
  border-radius: 10px;
}
.local{
  background-color:#BCD5C1;
  color:#3a4f3f;
}

.warning_icon{
  width:45px;
  height:80px;
  // opacity: 100% !important;
  // z-index:1 !important;
}
.warning_icon_small{
  width:25px;
  height:35px;
  cursor: pointer;
}
.warning__notice{
  z-index:1 !important;
  display: none;
  position: absolute;
  width: 250px;
  padding: 8px;
  right:10px;
  letter-spacing: 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #3a4f3f;
  font-weight:bold;
  color: #fff;
  font-size: 14px;
}
.warning__notice:after{
  z-index:1 !important;
  position: absolute;
  bottom: 100%;
  // left: 50%;
  width: 0;
  height: 0;
  margin-left: -50px;
  border: solid transparent;
  border-color: rgba(51, 51, 51, 0);
  border-bottom-color: #333;
  border-width: 10px;
  pointer-events: none;
  content: ' ';
}
.warning_icon_small:hover + .warning__notice {
  display: block;
  transition: all 300ms ease-in-out;
}
.power_icon{
  width:40px;
  height:30px;
  cursor:pointer;
  float:right !important;
}
.auto_icon{
  cursor:pointer;
  width:25px;
  height:25px;
  filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(308deg) brightness(102%) contrast(102%);
}
.auto_icon:hover{
  filter: invert(85%) sepia(45%) saturate(453%) hue-rotate(341deg) brightness(100%) contrast(104%);
}
// 애니메이션
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink-effect 2s step-end infinite;
}

// 색상
.device_run { // Animated
  z-index:0;

    background-image: repeating-linear-gradient(0deg, #508B7E, #508B7E 10px, transparent 10px, transparent 20px, #508B7E 20px), repeating-linear-gradient(90deg, #508B7E, #508B7E 10px, transparent 10px, transparent 20px, #508B7E 20px), repeating-linear-gradient(180deg, #508B7E, #508B7E 10px, transparent 10px, transparent 20px, #508B7E 20px), repeating-linear-gradient(270deg, #508B7E, #508B7E 10px, transparent 10px, transparent 20px, #508B7E 20px);
    background-size: 3px calc(100% + 20px), calc(100% + 20px) 3px, 3px calc(100% + 20px) , calc(100% + 20px) 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    animation: borderAnimation 1s infinite linear;

  @keyframes borderAnimation {
  from { background-position: 0 0, -20px 0, 100% -20px, 0 100%; }
  to { background-position: 0 -20px, 0 0, 100% 0, -20px 100%; }
  }
}
//color
.result_red{
  color:#88191a !important;
}
.result_blue{
  color:#184D7A !important;
}

.gray{
  background-color: rgba( 220,220,220, 0.5 );
  z-index:1;
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}
.stop_mode{
  filter: invert(8%) sepia(50%) saturate(7450%) hue-rotate(350deg) brightness(101%) contrast(88%);
}
.run_mode{
  filter: invert(93%) sepia(20%) saturate(132%) hue-rotate(80deg) brightness(95%) contrast(89%);
}
.map_no{
  font-size: 11px;
  color: #504f4f;
}

.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
  }

</style>